import axios from 'axios';
import { queryParamsBuilder } from 'core/helpers/queryParamsHelpers';
import axiosInstance from 'core/interceptors/authInterceptor';
import { IMe } from 'core/interfaces/account';
import { IAccountsInventoryOptions } from 'core/interfaces/accountApi';
//import type { paths, components } from 'core/types/schema'; // generated by openapi-typescript

const changePassword = async (email: string) => {
  await axios({
    method: 'post',
    url: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
    headers: { 'content-type': 'application/json' },
    data: {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
      email,
      connection: 'Username-Password-Authentication',
    },
  });
};

//type myType = components['schemas']['AccountingDto'];

const getAccount = async () => {
  return await axiosInstance.get(`/me`);
};

const patchAccount = async ({ language, phoneValue, notifications, picture }: Partial<IMe>) => {
  return await axiosInstance.patch(`/me`, {
    language,
    phoneValue,
    notifications,
    picture,
  });
};

const getAccounts = async (options: IAccountsInventoryOptions) => {
  const queryParams = queryParamsBuilder(options);
  return await axiosInstance.get(`/accounts${queryParams}`);
};

const getFavoriteAccounts = async (options: IAccountsInventoryOptions) => {
  const queryParams = queryParamsBuilder(options);
  return await axiosInstance.get(`/accounts/favorites${queryParams}`);
};

const getAccountsListDetail = async (options: IAccountsInventoryOptions) => {
  const queryParams = queryParamsBuilder(options);

  return await axiosInstance.get(`/accounts/list/detail/${queryParams}`);
};

const getAccountById = async (accountId: string) => {
  return await axiosInstance.get(`/account/${accountId}`);
};

const fetchCommonGroups = async (accountIds: string[], groupId: string | null = null) => {
  return await axiosInstance.post(`/groups/shared`, { accountIds, groupId });
};

const getAccountsTotalByCompany = async (idCompany: number) => {
  return await axiosInstance.get(`/company/${idCompany}/count/accounts`);
};

export {
  patchAccount,
  getAccountById,
  getAccountsListDetail,
  changePassword,
  getAccount,
  getAccounts,
  getFavoriteAccounts,
  fetchCommonGroups,
  getAccountsTotalByCompany,
};

import { Colorfill } from '@bringyourculturelive/design-system';
import styled from '@emotion/styled';
import { Layout, theme } from 'antd';

const { getDesignToken } = theme;

const globalToken = getDesignToken();

const { sizeLG } = globalToken;

export const AntContactFormsLayoutWrapper = styled(Layout)(() => ({
  height: '100svh',
  background: Colorfill,
  '&.isMobile-true': {
    '>.autoHeightOnMobile': {
      height: 'auto',
    },
  },
  '&.isMobile-false': {
    '& .autoHeightOnMobile': {
      height: '100%',
    },
    '& .marginInlineEndSizeLGOnDesktop': {
      marginInlineEnd: sizeLG,
    },
  },
}));

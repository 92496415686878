import styled from '@emotion/styled';
import { Image, Menu } from 'antd';

export const ImageFullHeightCover = styled(Image)`
  height: 100% !important;
  object-fit: cover !important;
`;

export const MenuContent = styled(Menu)`
  border: 0;
`;

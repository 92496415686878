export const SplashScreen = () => (
  <div className="preloading">
    <svg
      width="201"
      height="33"
      viewBox="0 0 201 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.6119 32.8961C27.8095 32.8961 26.2068 32.4968 24.8038 31.6981C23.4008 30.8995 22.3026 29.7961 21.5249 28.3932C20.7472 26.9903 20.3478 25.3667 20.3478 23.5382V11.1696H25.2295V23.3176C25.2295 24.3526 25.4029 25.2301 25.7392 25.9605C26.0755 26.6856 26.5799 27.2372 27.2525 27.6261C27.9146 28.0044 28.7133 28.204 29.6277 28.204C31.0517 28.204 32.1342 27.7784 32.8908 26.9378C33.6423 26.0971 34.0206 24.8886 34.0206 23.3228V11.1749H38.9023V23.5435C38.9023 25.4088 38.5134 27.0376 37.7252 28.4457C36.937 29.8486 35.8493 30.9468 34.462 31.7244C33.0748 32.5021 31.4616 32.9014 29.6277 32.9014L29.6171 32.8909L29.6119 32.8961Z"
        fill="#100066"
      />
      <path
        d="M67.6456 11.1696V15.6988H62.4434V32.9066H57.4619V15.6988H52.2545V11.1696H57.4619V2.1217H62.4434V11.1696H67.6456Z"
        fill="#100066"
      />
      <path
        d="M79.5791 32.8961C77.7767 32.8961 76.174 32.4968 74.771 31.6981C73.368 30.8995 72.2697 29.7961 71.492 28.3932C70.7143 26.9903 70.315 25.3667 70.315 23.5382V11.1696H75.1966V23.3176C75.1966 24.3526 75.37 25.2301 75.7063 25.9605C76.0426 26.6856 76.5471 27.2372 77.2197 27.6261C77.8818 28.0044 78.6805 28.204 79.5948 28.204C81.0189 28.204 82.1013 27.7784 82.858 26.9378C83.6094 26.0971 83.9878 24.8886 83.9878 23.3228V11.1749H88.8694V23.5435C88.8694 25.4088 88.4806 27.0376 87.6924 28.4457C86.9042 29.8486 85.8164 30.9468 84.4292 31.7244C83.0419 32.5021 81.4287 32.9014 79.5948 32.9014L79.5843 32.8909L79.5791 32.8961Z"
        fill="#100066"
      />
      <path
        d="M98.6905 20.7272V20.7483L98.7011 20.7378L98.6852 20.7219L98.6905 20.7272ZM98.6905 20.7272V20.7483L98.7011 20.7378L98.6852 20.7219L98.6905 20.7272Z"
        fill="#100066"
      />
      <path
        d="M107.356 11.1696C106.483 10.7703 105.495 10.5759 104.397 10.5759C101.901 10.5759 100.041 11.4376 98.8113 13.1505C98.7798 13.1978 98.743 13.245 98.7115 13.2976V11.033H93.6932V32.9066H98.7115V20.0231C98.8166 18.4993 99.2685 17.3644 100.057 16.5973C100.966 15.7146 102.122 15.2732 103.52 15.2732C104.155 15.2732 104.718 15.3573 105.206 15.5412C105.695 15.7251 106.131 16.0246 106.531 16.4502L109.815 13.0822C109.058 12.1994 108.244 11.5584 107.356 11.1643V11.1696ZM98.7062 20.7324L98.6957 20.7482V20.7272H98.7115L98.7062 20.7324Z"
        fill="#100066"
      />
      <path d="M134.759 32.8909V0.435089H139.719V32.8909H134.759Z" fill="#100066" />
      <path
        d="M175.819 11.2222L165.946 32.8541H163.066L153.192 11.2222H158.694L164.606 25.0567L170.549 11.2222H175.819Z"
        fill="#100066"
      />
      <path
        d="M197.915 26.2704C199.433 26.2704 200.664 25.0401 200.664 23.5224C200.664 22.0048 199.433 20.7744 197.915 20.7744C196.398 20.7744 195.167 22.0048 195.167 23.5224C195.167 25.0401 196.398 26.2704 197.915 26.2704Z"
        fill="#F1572C"
      />
      <path
        d="M195.919 28.4458C195.293 28.1883 194.71 27.8047 194.2 27.2951C193.643 26.7381 193.233 26.0866 192.976 25.3877C192.718 25.7871 192.408 26.1601 192.051 26.5174C190.706 27.8625 189.066 28.5351 187.122 28.5298C185.982 28.5298 184.941 28.2881 184.001 27.81L196.392 15.4204C196.134 15.042 195.845 14.6742 195.535 14.3117C195.356 14.112 195.178 13.9176 194.989 13.7285C194.484 13.2241 193.964 12.7827 193.407 12.4044C191.583 11.1276 189.482 10.4918 187.112 10.4918C184.038 10.4918 181.426 11.5742 179.261 13.7285C177.101 15.888 176.024 18.5046 176.024 21.5784C176.029 23.9376 176.676 26.0235 177.963 27.852C178.347 28.4037 178.793 28.9239 179.293 29.4283C179.829 29.9642 180.391 30.4319 180.979 30.8364C182.777 32.0607 184.826 32.6807 187.138 32.6965C190.228 32.6965 192.85 31.6246 194.999 29.4703C195.33 29.1393 195.635 28.7978 195.913 28.4458H195.919ZM180.974 24.8466C180.449 23.8745 180.191 22.7869 180.186 21.5994C180.186 19.6763 180.864 18.0422 182.22 16.6919C183.565 15.3468 185.194 14.6742 187.112 14.6742C188.31 14.6742 189.397 14.9317 190.375 15.4466L180.974 24.8466Z"
        fill="#100066"
      />
      <path d="M43.7103 32.9066V0.450836H48.6708V32.9066H43.7103Z" fill="#100066" />
      <path
        d="M11.2992 15.3625C11.7196 15.3257 12.1452 15.31 12.5708 15.31C14.0579 15.31 15.3558 15.5307 16.6695 15.7829L17.1372 15.8722V11.033L16.8167 10.9752C15.4609 10.7177 14.1157 10.4865 12.5656 10.4865C12.0979 10.4865 11.6302 10.5076 11.1678 10.5443C8.43012 10.7913 6.076 11.9315 3.97411 14.0332C1.82493 16.1822 0.710928 18.7673 0.663635 21.736C0.684654 23.2335 0.973664 24.6206 1.52541 25.9079C2.07716 27.1952 2.89163 28.3774 3.96885 29.4493C6.076 31.551 8.43011 32.6912 11.1626 32.9381C11.6302 32.9802 12.0979 33.0012 12.5603 33.0012C14.1105 33.0012 15.4557 32.77 16.8114 32.5073L17.1319 32.4495V27.6103L16.6643 27.6996C15.3506 27.9466 14.0527 28.1725 12.5656 28.1725C12.1452 28.1725 11.7143 28.1567 11.2939 28.1199C7.98346 27.8152 5.48221 25.0725 5.47696 21.7465C5.47696 18.4153 7.97821 15.6725 11.2939 15.373V15.3573L11.2992 15.3625Z"
        fill="#100066"
      />
      <path
        d="M126.178 25.95C126.01 26.1601 125.826 26.365 125.626 26.5594C124.27 27.9151 122.62 28.5876 120.671 28.5876C119.247 28.5876 118.143 28.1568 117.529 27.8625L130.003 15.3993C129.814 15.1208 129.599 14.8319 128.59 13.6969C128.085 13.1873 127.555 12.7407 127.003 12.3571C125.169 11.075 123.051 10.434 120.666 10.434C117.576 10.434 114.943 11.5217 112.768 13.6917C110.592 15.867 109.505 18.4994 109.51 21.5889C109.515 23.9586 110.167 26.0655 111.459 27.8993C111.848 28.4563 112.295 28.9817 112.794 29.4861C113.33 30.022 113.898 30.4949 114.491 30.9048C116.299 32.1343 118.364 32.7543 120.692 32.7753C123.803 32.7753 126.441 31.6929 128.606 29.5334C128.795 29.3442 128.973 29.1498 129.142 28.9554L126.183 25.95H126.178ZM114.486 24.8728C113.955 23.8903 113.693 22.8026 113.693 21.5994C113.693 19.6658 114.376 18.0212 115.737 16.6604C117.087 15.31 118.727 14.6322 120.666 14.6322C121.874 14.6322 122.967 14.8897 123.945 15.4098L114.481 24.8728H114.486Z"
        fill="#100066"
      />
      <path
        d="M147.302 7.21839C146.456 7.21839 145.768 6.93466 145.205 6.36194C144.643 5.78922 144.37 5.07989 144.37 4.24971C144.37 3.41953 144.648 2.70495 145.205 2.13749C145.762 1.57003 146.461 1.28104 147.302 1.28104C148.143 1.28104 148.884 1.56477 149.414 2.13749C149.956 2.70495 150.224 3.41953 150.224 4.24971C150.224 5.07989 149.945 5.79447 149.414 6.36194C148.873 6.9294 148.169 7.21839 147.302 7.21839Z"
        fill="#100066"
      />
      <path
        d="M149.919 11.2905V32.8698H144.958V11.2379H145.006C145.778 11.5742 146.629 11.7581 147.528 11.7581C148.369 11.7581 149.183 11.59 149.924 11.2905H149.919Z"
        fill="#100066"
      />
    </svg>
    <div className="balls">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

SplashScreen.displayName = 'Components_SplashScreen';

import { memo, Suspense, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { Container, TabBar, DisplayNoneDownSize, Provider } from '@bringyourculturelive/design-system';
import { Col, Row, theme } from 'antd';
import { Content } from 'antd/es/layout/layout';
import Store from './DefaultLayout.store';
import Spinner from 'components/Spinner';
import { ItemType } from 'antd/lib/menu/interface';
import { House, ShoppingBag } from 'lucide-react';
import { AntHeader } from 'components/AntHeader';
import {
  AntLayoutWrapper,
  RightSideBarContentWrapper,
  StickyContainer,
  StickyFooter,
} from './AntLayout.style';

export const AntLayout = memo(() => {
  const { useToken } = theme;
  const { sizeLG, sizeMS, screenMDMax } = useToken().token;
  const { settings } = Store.useStore((state) => state);

  const headerContainer = useRef<HTMLDivElement>(null);

  const { RightSideBarContent, hasRightSideBarContent } = settings;

  const tabItems: ItemType[] = [
    {
      icon: <ShoppingBag />,
      key: 'marketplace',
    },
    {
      icon: <House />,
      key: 'home',
    },
  ];

  const displayNoneDownSizeProps = {
    children: <></>,
    size: screenMDMax,
    replacement: <TabBar items={tabItems} />,
  };

  const scrollAreaHeight = `calc(100dvh - ${headerContainer.current?.offsetHeight}px)`;

  return (
    <Provider
      theme={{
        components: {
          Layout: {
            headerPadding: 0,
            footerPadding: 0,
            headerHeight: 'auto',
          },
        },
      }}
    >
      <AntLayoutWrapper>
        <StickyContainer>
          <Container ref={headerContainer}>
            <AntHeader />
          </Container>
        </StickyContainer>
        <Content>
          <Container>
            <Row gutter={[sizeLG, sizeLG]}>
              {/*Left Col */}
              <Col
                lg={{ span: hasRightSideBarContent ? sizeMS : sizeLG }}
                flex="auto"
                style={{
                  paddingBlock: sizeMS,
                }}
              >
                <Suspense fallback={<Spinner />}>
                  <Outlet />
                </Suspense>
              </Col>
              {/*Right Col */}
              {hasRightSideBarContent && (
                <RightSideBarContentWrapper
                  lg={{ span: 8 }}
                  xs={{ span: 0 }}
                  style={{
                    maxHeight: scrollAreaHeight,
                    minHeight: scrollAreaHeight,
                    top: `${headerContainer.current?.offsetHeight}px`,
                  }}
                >
                  <div className="RightSideBarContentWrapper___inner">
                    <RightSideBarContent />
                  </div>
                </RightSideBarContentWrapper>
              )}
            </Row>
          </Container>
        </Content>
        <StickyFooter>
          <DisplayNoneDownSize {...displayNoneDownSizeProps} />
        </StickyFooter>
      </AntLayoutWrapper>
    </Provider>
  );
});

AntLayout.displayName = 'Layouts_AntLayout';

import { Colorprimarybg, FoundationBlueberryBlueberry6, White } from '@bringyourculturelive/design-system';
import styled from '@emotion/styled';
import { Col, Flex, Layout } from 'antd';

import { theme } from 'antd';
import { Footer } from 'antd/es/layout/layout';

const { getDesignToken } = theme;

const globalToken = getDesignToken();

const { sizeMS, padding, paddingXL } = globalToken;

export const AntLayoutWrapper = styled(Layout)(() => ({
  display: 'grid',
  gridTemplateRows: ' auto 1fr auto',
  minHeight: '100svh',
  background: White,
}));

export const StickyFooter = styled(Footer)(() => ({
  position: 'sticky',
  bottom: 0,
}));

export const RightSideBarContentWrapper = styled(Col)(() => ({
  paddingBlock: sizeMS,
  overflow: 'hidden',
  position: 'sticky',
  '& .RightSideBarContentWrapper___inner': {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
}));

export const StickyContainer = styled.div(() => ({
  padding: 0,
  position: 'sticky',
  top: '0',
  zIndex: 2,
  backgroundColor: Colorprimarybg,
}));

export const ContentWrapper = styled(Flex)(() => ({
  paddingInline: padding,
  paddingBlock: paddingXL,
}));

export const AsideWrapper = styled.div(() => ({
  backgroundColor: FoundationBlueberryBlueberry6,
}));

import { memo, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { Provider } from '@bringyourculturelive/design-system';
import { Row, Col, theme, Image } from 'antd';
import Spinner from 'components/Spinner';
import { AntContactFormsLayoutWrapper } from './AntContactFormsLayout.style';
import { useWindowSize } from 'react-use';
import { AsideWrapper, ContentWrapper } from './AntLayout.style';

export const AntContactFormsLayout = memo(() => {
  const { useToken } = theme;
  const { screenMDMax } = useToken().token;
  const { width } = useWindowSize();
  const isMobile = width <= screenMDMax;

  return (
    <Provider
      theme={{
        components: {
          Typography: {
            titleMarginBottom: 0,
            titleMarginTop: 0,
          },
        },
      }}
    >
      <AntContactFormsLayoutWrapper className={`isMobile-${isMobile}`}>
        <Row gutter={[0, 0]} align="top" className="autoHeightOnMobile">
          <Col span={24} lg={{ span: 8 }} xxl={{ span: 6 }} className="autoHeightOnMobile">
            <AsideWrapper className="autoHeightOnMobile marginInlineEndSizeLGOnDesktop">
              <Image
                src={`${process.env.PUBLIC_URL}/img/banner-${isMobile ? 'mobile' : 'desktop'}.jpg`}
                width={'100%'}
                preview={false}
              />
            </AsideWrapper>
          </Col>
          <Col span={24} lg={{ span: 9 }}>
            <Suspense fallback={<Spinner />}>
              <ContentWrapper justify={isMobile ? 'center' : 'inherit'}>
                <Outlet />
              </ContentWrapper>
            </Suspense>
          </Col>
        </Row>
      </AntContactFormsLayoutWrapper>
    </Provider>
  );
});

AntContactFormsLayout.displayName = 'Layouts_AntContactFormsLayout';
